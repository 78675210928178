<template>
  <UserForm mode="Ubah" module="User"> </UserForm>
</template>

<script>
import UserForm from './form';

const UserUpdate = {
  name: 'UserUpdate',
  components: { UserForm },
};

export default UserUpdate;
</script>
